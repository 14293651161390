module.exports = {
  background: '#d9d9d9',
  backgroundDark: '#f7e1d7',

  text: '#5aa9e6',

  primary: '#6c757d',
  primaryLight: '#f7c59f',
  primaryDark: '#004e89',

  secondary: '#0077b6',
  secondaryLight: '#ffa69e',
  secondaryDark:  '#1a659e',
};

favre =
{
  background: '#efefd0',
  backgroundDark: '#f7e1d7',

  text: '#5aa9e6',

  primary: '#ff9770',
  primaryLight: '#f7c59f',
  primaryDark: '#004e89',

  secondary: '#d9d9d9',
  secondaryLight: '#ffa69e',
  secondaryDark:  '#1a659e',
};
